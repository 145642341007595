<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <div align="center">

          <div v-if="gstate === 'start'">

            <v-btn
                color="success"
                class="mr-4"
                @click="goToNextWord"
            >
              Start
            </v-btn>
          </div>

          <div v-if="gstate === 'guess'">

            <v-form
                ref="form"
                lazy-validation
                v-on:submit.prevent
            >
              <v-btn
                  color="success"
                  class="mr-4"
                  @click="speak"
              >
                Listen
              </v-btn>

              <v-text-field
                  ref="guessinput"
                  v-model="word"
                  :counter="20"
                  label="Spell word"
                  required
                  v-on:keydown.enter='validate'
              ></v-text-field>

              <v-btn
                  color="success"
                  class="mr-4"
                  @click="validate"
              >
                Check
              </v-btn>

            </v-form>

          </div>


          <div v-if="gstate === 'incorrectguess'">
            <v-form
                ref="form"
                lazy-validation
                v-on:submit.prevent
            >
              <v-alert
                  :value="true"
                  type="error"
              >
                You spelled "{{ this.correctSpelling }}" incorrectly.
              </v-alert>

              <div>
                <v-btn
                    type="submit"
                    color="success"
                    class="mr-4"
                    @click="goToNextWord"
                    ref="incorrectNextButton"
                >
                  Next
                </v-btn>

              </div>
            </v-form>
          </div>


          <div v-if="gstate === 'correctguess'">
            <v-form
                ref="form"
                lazy-validation
                v-on:submit="goToNextWord"
            >
              <v-alert
                  :value="true"
                  type="success"
              >
                You spelled "{{ this.correctSpelling }}" correctly.
              </v-alert>

              <v-btn
                  type="submit"
                  color="success"
                  class="mr-4"
                  @click="goToNextWord"
                  ref="correctNextButton"
              >
                Next
              </v-btn>
            </v-form>

          </div>


          <div v-if="gstate === 'done'">
            You are done!
          </div>

        </div>
      </v-flex>
    </v-layout>
  </v-container>

</template>

<script>
export default {
  data: () => ({
    gstate: 'start',
    done: [],
    word: '',
    correctSpelling: '',
    items: [
      'action',
      'mammal',
      'simply',
      'gesture',
      'solar',
      'pressure',
      'quote',
      'wrestle',
      'jealous',
      'partial',
      'temperature',
      'inquire',
      'museum',
      'especially',
      'patient',
      'intermission',
      'mechanic',
      'disastrous',
      'studios',
      'violence',
      'unsuccessful',
      'immediate',
      'acknowledgement',
      'unmistakable',
      'preferable',
      'climate',
      'surface',
      'further',
      'heroes',
      'grief',
      'sailor',
      'myth',
      'rehearse',
      'headache',
      'scarcely',
      'scientific',
      'marriage',
      'excellent',
      'league',
      'vault',
      'acquire',
      'requirement',
      'scissors',
      'descendant',
      'unbelievable',
      'independence',
      'strengthen',
      'desperately',
      'recyclable',
      'millionaire',
      'express',
      'gaze',
      'noisy',
      'squawk',
      'hyphen',
      'listening',
      'rumor',
      'governor',
      'grateful',
      'whose',
      'hangar',
      'sword',
      'ingredient',
      'ancient',
      'honorable',
      'vacuum',
      'accidentally',
      'vegetable',
      'systematic',
      'separately',
      'variety',
      'noticeable',
      'courageous',
      'exaggerate',
      'rhythmic',
      'increase',
      'teaspoon',
      'poem',
      'bough',
      'spaghetti',
      'recently',
      'safety',
      'rural',
      'separate',
      'ambulance',
      'emergency',
      'sentence',
      'comfortable',
      'nationality',
      'struggling',
      'persuade',
      'performance',
      'appearance',
      'audience',
      'lightning',
      'encyclopedia',
      'operational',
      'restaurant',
      'convenience',
      'mischievous',
    ],
  }),

  methods: {
    startFocus() {
      this.$refs.guessinput.focus()
    },
    speak() {
      let msg = new SpeechSynthesisUtterance();
      msg.text = this.correctSpelling;
      window.speechSynthesis.speak(msg);
    },
    validate() {
      // console.log('validation', this.word, this.correctSpelling)
      if (this.word.toLowerCase() === this.correctSpelling) {
        this.gstate = 'correctguess'
        this.done.push(this.word)

        this.$nextTick(() => {
          // this.$refs.correctNextButton.focus()
        });
      } else {
        this.gstate = 'incorrectguess'

        this.$nextTick(() => {
          // this.$refs.incorrectNextButton.focus();
          // this.$refs.form.focus();
        })
      }

      this.word = ''

    },
    goToNextWord() {
      if (!this.nextWord()) {
        this.gstate = 'done'
        return true
      }

      this.gstate = 'guess'

      this.$nextTick(() => {
        this.$refs.guessinput.focus()
      });
    },
    nextWord() {
      if (this.done.length === this.items.length) {
        return false
      } else {
        let nextWord = ''
        // while (this.done.includes(nextWord)) {
        nextWord = this.items[Math.floor(Math.random() * this.items.length)];
        // }
        // console.log('nextWord = ', nextWord)
        this.correctSpelling = nextWord
        this.speak()
        return true
      }
    }
  },
}
</script>