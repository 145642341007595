<template>
  <v-card
      class="mx-auto ma-6"
      width="400px"
  >
    <v-card-text>
      <v-toolbar
          color="secondary"
      >
        <v-card-title class="text-h6 font-weight-regular">
          Contact
        </v-card-title>
      </v-toolbar>
      <form>
        <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :counter="10"
            label="Name"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
        ></v-text-field>
        <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
        ></v-text-field>
        <v-select
            v-model="select"
            :items="items"
            :error-messages="selectErrors"
            label="Reason"
            required
            @change="$v.select.$touch()"
            @blur="$v.select.$touch()"
        ></v-select>
        <v-textarea
            counter
            clearable
            clear-icon="mdi-close-circle"
            autocomplete="message"
            label="Message"
        ></v-textarea>

        <v-btn
            class="mr-4 accent black--text"
            @click="submit"
        >
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, maxLength, email} from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  validations: {
    name: {required, maxLength: maxLength(10)},
    email: {required, email},
    select: {required},
    message: {required},
  },

  data: () => ({
    name: '',
    email: '',
    message: '',
    select: null,
    items: [
      'Web development',
      'Interior design',
    ],
  }),

  computed: {
    selectErrors() {
      const errors = []
      if (!this.$v.select.$dirty) return errors
      !this.$v.select.required && errors.push('Item is required')
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
      !this.$v.name.required && errors.push('Name is required.')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
  },

  methods: {
    submit() {
      this.$v.$touch()
    },
    clear() {
      this.$v.$reset()
      this.name = ''
      this.email = ''
      this.message = ''
      this.select = null
    },
  },
}
</script>